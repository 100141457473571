<script>
import NavigationComponent from "@/components/Navigation.vue";
import {initUtils} from "@telegram-apps/sdk";

export default {
  name: "ProfilePage",
  components: {NavigationComponent},
  data(){
    return {
      avatarLoaded : false
    }
  },
  methods : {
    waitAvatar(){
      if(this.$refs.avatar && this.$refs.avatar.complete){
        this.avatarLoaded=true;
      }
    },
    support(){
      let utils = initUtils();
      utils.openTelegramLink("https://t.me/Tagito_support")
    },
    openChannel(){
      let utils = initUtils();
      utils.openTelegramLink("https://t.me/+YxtYX7eHY89iZTNi")
    },
    openAbout(){
      let utils = initUtils();
      
      if(this.$i18n.locale == "ru"){
        utils.openLink("https://telegra.ph/Instrukciya-k-ispolzovaniyu-TMA-Tagito-09-18",{
          tryInstantView : true
      })
      }
      else{
        utils.openLink("https://telegra.ph/Instructions-for-using-TMA-Tagito-09-30",{
          tryInstantView : true
        })
      }
    }
  },

  mounted() {


  }
}
</script>

<template>
  <div class="page">

    <div class="fake-avatar">{{$store.state.userInfo.first_name[0]}}</div>
    <img  v-show="avatarLoaded" @load="waitAvatar" ref="avatar" :src="`https://t.me/i/userpic/160/${$store.state.userInfo.username}.jpg`" class="avatar" alt="avatar" width="125" height="125">
    <span class="name">{{$store.state.userInfo.first_name}} {{$store.state.userInfo.last_name}}</span>
    <div class="container">
      <div class="menu w-full flex flex-col gap-2">
        <div :class="'menu-item w-full'" @click="openChannel()">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/telegram.svg" alt=""></div>
          <span>{{$t("profile.telegram")}}</span>
        </div>
        <div :class="'menu-item w-full'" @click="support()">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/support2.svg" alt=""></div>
          <span>{{$t("profile.support")}}</span>
        </div>
        <div :class="'menu-item w-full'" @click="$router.push('/settings/locale')">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/language.svg" alt=""></div>
          <span>{{$t("profile.locale")}}</span>
        </div>
        <div :class="'menu-item w-full'" @click="openAbout()">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/about.svg" alt=""></div>
          <span>{{$t("profile.about")}}</span>
        </div>
       
      </div>
    </div>

  </div>
  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
  .name{
    font-weight: 500;
    font-size: 18px;
    color: #2D2D2D;
  }
  .page{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    gap: 10px;
  }
  .fake-avatar{
    height: 125px;
    width: 125px;
    border-radius: 50%;
    position: relative;
    background: #24b9c5;
    color: #fff;
    font-size: calc(5vh);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .avatar{
    border-radius: 50%;
    position: absolute;
  }

  .menu-item-icon{
    height: 35px;
    width: 35px;
    
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-item{
    background: #fff;
    display: flex;
    box-shadow: 0px 0px 10px 0px #0000000D;
    font-size: 15px;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 15px;
  }
</style>